import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/header/header.component";
import headerImage from "../../img/clinical-trials/hero.jpg";
import { hideMask } from "../../components/navigation/navigation.component";
import "./clinical-trials.styles.scss";
import TextSectionButtonComponent from "../../components/text-section-button/text-section-button.component";
import CLINICAL_TRIAL_DATA from "./clinical-trials.data";

function ClinicalTrials() {
    const [clinicalTrials, setClinicalTrials] = useState([]);
    const [diseaseTypeArrs, setDiseaseTypeArr] = useState([]);
    const [selectedDisease, setSelectedDisease] = useState("");

    useEffect(() => {
        const data = CLINICAL_TRIAL_DATA.locations;

        const diseaseTypes = [...new Set(data.map((trial) => trial.disease_site))];
        setDiseaseTypeArr(diseaseTypes);

        setClinicalTrials(data);
    }, []);

    const onCategoryChange = (event) => {
        const diseaseType = event.target.value;
        setSelectedDisease(diseaseType === "Select" ? "" : diseaseType);
    };

    const filteredClinicalTrials = clinicalTrials.filter((trial) =>
        trial.disease_site.toLowerCase().includes(selectedDisease.toLowerCase())
    );

    return (
        <>
            <main className="page-body clinical-trials-page" onClick={hideMask}>
                <HeaderComponent
                    imageUrl={headerImage}
                    title="Clinical trials"
                    isTitleDash={false}
                    shouldHideHomeButton={false}
                />
                <div className="container my-5">
                    <div className="row">
                        <TextSectionButtonComponent
                            htmlCopy={`
                                Clinical trials are research studies where interested patients can help discover new ways to improve treatment and outcomes for themselves and other cancer patients. 
                                Optum Care Cancer Care provides the community clinical trials offered by pharmaceutical industry sponsors and has access to the trials offered by the National Cancer Institute. 
                                Our continued involvement in clinical research helps us find new ways to prevent, diagnose, and treat cancer while managing the symptoms and reducing the side effects of treatment. 
                                
                                <br><br>
                                
                                <p> Please contact the clinical trials office for more information. Call <a href="tel:+17027248787"> 1-702-724-8787</a>, 
                                extension #209, and/or email <a href = "mailto: clinicaltrials@optum.com">clinicaltrials@optum.com</a> </p>`}
                            customSectionClass={"text-center"}
                        />
                    </div>
                </div>

                <div className="container my-5">
                    <div className="row">
                        <div className="col-md-6">
                            <p>{filteredClinicalTrials.length} clinical trials</p>
                        </div>
                        <div className="col-md-6">
                            <select
                                onChange={onCategoryChange}
                                className="float-right diseaseTypeDropdown"
                            >
                                <option value="Select">Show: All disease types</option>
                                {diseaseTypeArrs.map((diseaseType) => (
                                    <option value={diseaseType} key={diseaseType}>
                                        {diseaseType}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <table className="table table-hover hide-mobile">
                        <thead>
                            <tr>
                                <th scope="col">Disease type</th>
                                <th scope="col">Sponsor/Trial Number</th>
                                <th scope="col">Study title</th>
                                <th scope="col">Agent Device</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredClinicalTrials.map((clinicalTrial, index) => (
                                <tr key={index}>
                                    <td>{clinicalTrial.disease_site}</td>
                                    <td>{clinicalTrial.study_number}</td>
                                    <td>{clinicalTrial.study_title}</td>
                                    <td>{clinicalTrial.agent_device}</td>
                                    <td>
                                        {clinicalTrial.link_trial !== "#" ? (
                                            <a
                                                href={clinicalTrial.link_trial}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <button className="btn btn-primary">View trial</button>
                                            </a>
                                        ) : (
                                            <p>
                                                Please contact the clinical trials office for more
                                                information. Call
                                                <a href="tel:+17027248787"> 1-702-724-8787</a>,
                                                extension #209, and/or email{" "}
                                                <a href="mailto:clinicaltrials@optum.com">
                                                    clinicaltrials@optum.com
                                                </a>
                                            </p>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Responsive design for mobile */}
                    <div className="hide-desktop">
                        {filteredClinicalTrials.map((clinicalTrial, index) => (
                            <div className="card p-4 mb-4" key={index}>
                                <h4 className="mb-1">{clinicalTrial.study_title}</h4>
                                <p>{clinicalTrial.disease_site}</p>
                                <p>{clinicalTrial.agent_device}</p>
                                {clinicalTrial.link_trial !== "#" ? (
                                    <a
                                        href={clinicalTrial.link_trial}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <button className="btn btn-primary">View trial</button>
                                    </a>
                                ) : (
                                    <p>
                                        Please contact the clinical trials office for more
                                        information. Call
                                        <a href="tel:+17027248787"> 1-702-724-8787</a>, extension
                                        #209, and/or email{" "}
                                        <a href="mailto:clinicaltrials@optum.com">
                                            clinicaltrials@optum.com
                                        </a>
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </main>
        </>
    );
}

export default ClinicalTrials;
