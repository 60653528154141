const LOCATIONS_DATA = {
    //**UPDATE FOR GOOGLE MAPS LINKS NEEDED FOR EACH LOCATION**/
    locations: [
        {
            title: 'Optum Care Cancer Care Fort Apache',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO9JyFYi4yIARbZ65rPu9T38&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.076087,-115.297047&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=6190+S+Fort+Apache+Rd+Las+Vegas,+NV+89148',
            address1: '6190 S. Fort Apache Rd.',
            address2: 'Las Vegas, NV 89148',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 1,
            text: "On Nov. 25, 2024, this location will be moving to Optum Cancer Care Seven Hills and Optum Cancer Care on Charleston Blvd. "
        },
      /*  {
            title: 'Optum Care Cancer Care Mountain View',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJcVSiv5rqyIARdCOEec_qa-E&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/3150+N+Tenaya+Way,+Las+Vegas,+NV+89128/@36.216809,-115.249539,15z/data=!4m5!3m4!1s0x80c8ea9abfa25471:0xe16beacf79842374!8m2!3d36.2168087!4d-115.2495388?hl=en-US',
            address1: '3150 N. Tenaya Way, Suite 160',
            address2: 'Las Vegas, NV 89129',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 2
        },*/
        {
            title: 'Optum Care Cancer Care Seven Hills',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJWZyHnHHOyIARFeUH2hePCrY&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=35.999108,-115.124618&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=3175+St+Rose+Pkwy+Trail+Henderson,+NV+89052',
            address1: '3175 St. Rose Pkwy. ',
            address2: 'Henderson, NV 89052',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 3
        },
        {
            title: 'Optum Care Cancer Center',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbwQdJenDyIAR4hiNqrAfAlI&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.159456,-115.173352&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=2300+W+Charleston+Blvd+Las+Vegas,+NV+89102',
            address1: '2300 W. Charleston Blvd',
            address2: 'Las Vegas, NV 89106',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 4
        },
        {
            title: 'Radiation Oncology Centers of Nevada - Eastern',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJY6agZODEyIARBgRt2PB1T8o&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/3980+S+Eastern+Ave,+Las+Vegas,+NV+89119/@36.117061,-115.11846,15z/data=!4m5!3m4!1s0x80c8c4e064a0a663:0xca4f75f0d86d0406!8m2!3d36.1170613!4d-115.1184603?hl=en-US',
            address1: '3980 S. Eastern Ave.',
            address2: 'Las Vegas, NV 89119',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 5
        },
        {
            title: 'Radiation Oncology Centers of Nevada - Tonopah',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJxWNKGOrDyIAR0WDlkyO8d4k&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps/place/624+S+Tonopah+Dr,+Las+Vegas,+NV+89106/@36.163193,-115.170435,15z/data=!4m5!3m4!1s0x80c8c3ea184a63c5:0x8977bc2393e560d1!8m2!3d36.1631925!4d-115.170435?hl=en-US',
            address1: '624 S. Tonopah Dr.',
            address2: 'Las Vegas, NV 89106',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 6
        },
        {
            title: 'Optum Care Breast Care Seven Hills',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:EiszMTc1IFN0IFJvc2UgUGt3eSwgSGVuZGVyc29uLCBOViA4OTA1MiwgVVNBIlESTwo0CjIJl5oIEXLOyIARw8-HzarN6HIaHgsQ7sHuoQEaFAoSCRlMCpbozciAEfSXkeCiC8hADBDnGCoUChIJ9Zr_UYfOyIARafVS4VOcKDM&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=35.999004,-115.124561&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=3175+St+Rose+Pkwy+Henderson,+NV+89052',
            address1: '3175 St. Rose Pkwy.',
            address2: 'Henderson, NV 89052',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '',
            id: 7
        },
        {
            title: 'Optum Care Breast Care',
            mapUrl: 'https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbwQdJenDyIAR4hiNqrAfAlI&key=AIzaSyAjS1V-2CtBZj4tKu4Y4awhW6iWLti8D4E&zoom=15',
            linkUrl: 'https://www.google.com/maps?ll=36.159456,-115.173352&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=2300+W+Charleston+Blvd+Las+Vegas,+NV+89102',
            address1: '2300 W. Charleston Blvd',
            address2: 'Las Vegas, NV 89106',
            address3: '',
            hours: 'Monday–Friday, 8 a.m–5 p.m.',
            phone: '1-702-724-8787',
            fax: '1-702-821-1122',
            id: 8
        },
    ]
}

export default LOCATIONS_DATA;


